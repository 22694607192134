.save-menu {
  width: 100%;
  z-index: 78;
  position: absolute;
  color: $title-font-color;
  background-color: #ffecb3;

  &.sticky-auto {
    position: fixed;
    top: auto;
    margin-top: 5px;
    min-width: 0;
  }

  &.sticky {
    position: fixed;
    top: 0;
    min-width: 0;
  }

  .content {
    height: 100%;
  }

  .buttons {
    margin-left: auto;
  }
}
