.toast-title {
  font-weight: bold;
}
.toast-message {
  padding-right: 25px;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  top: 3px;
  float: right;
  color: #ffffff;
  opacity: 0.8;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
#toast-container {
  @include no-select();
  min-width: 300px;
  max-width: 500px;
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: fixed;
  top: 57px;
  right: 12px;
  margin: 0 0 6px;
  padding: 6px 12px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  white-space: nowrap;
  color: #ffffff;
  opacity: 0.8;
}
#toast-container > :hover {
  opacity: 1;
  cursor: pointer;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  z-index: 1010;
  background-color: $success-color;
}
.toast-error {
  z-index: 1050;
  background-color: $error-color;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}
/*Animations*/
.toast {
  opacity: 1 !important;
}
.toast.ng-enter {
  opacity: 0 !important;
  transition: opacity 0.3s linear;
}
.toast.ng-enter.ng-enter-active {
  opacity: 1 !important;
}
.toast.ng-leave {
  opacity: 1;
  transition: opacity 0.3s linear;
}
.toast.ng-leave.ng-leave-active {
  opacity: 0 !important;
}
