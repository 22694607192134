.supplier-selector {
  @include no-select();
  cursor: pointer;
  width: 100%;

  span {
    @include text-truncate();
    display: inline-block;
    max-width: 260px;
  }

  .main-flag {
    display: inline-block;
    line-height: 24px;
    vertical-align: top;
  }

  .selector {
    &:after {
      content: '\f2f2';
      font-size: 26px;
      font-family: Material-Design-Iconic-Font;
      right: 15px;
      top: 3px;
      position: absolute;
    }
  }
}
