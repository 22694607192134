.breadcrumbs {
  height: 22px;
  cursor: pointer;

  span {
    color: $caption-font-color;
    font-size: 14px;
    margin-right: 5px;
    overflow: hidden;
    padding: 0;
    display: block;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
  }
}
