.validation-errors {
  background-color: $error-color;
  border-radius: 3px;
  box-shadow: $whiteframe-shadow-z2;
  box-sizing: border-box;
  color: $base-font-color-light;
  font-size: 15px;
  padding: 15px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li:not(:first-child) {
    margin-top: 5px;
  }

  > .md-button {
    height: 16px;
    padding: 0 5px;
  }
}

md-dialog .validation-errors {
  margin: 10px;
}

.entities .validation-errors {
  margin-bottom: 10px;
}
