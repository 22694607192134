.article {
  .variant-picker {
    font-size: 20px;
    margin-top: 5px;
    word-break: break-all;
  }

  .picker-selected {
    color: $display-font-color;
  }

  .content-header {
    min-height: 62px;
  }
}
