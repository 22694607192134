.md-button.md-dynamic {
  min-height: 0;
  min-width: 0;
  line-height: 1;
}

.md-button.md-small {
  font-size: 14px;
  height: auto;
  margin: 4px;
  min-height: 0;
  padding: 8px 12px;
  width: auto;
}

.md-button.md-mini {
  font-size: 14px;
  height: auto;
  margin: 2px;
  min-height: 0;
  padding: 3px 5px;
  width: auto;
}

.md-button.md-icon {
  font-size: 24px;
  height: auto;
  width: auto;
  margin: 4px;
  min-height: 0;
  min-width: 0;
  padding: 5px 12px;
  line-height: 1;
}

.md-button.md-raised {
  color: $caption-font-color;
}

.a.md-button:not(:hover).md-focused,
.md-button:not(:hover).md-focused {
  background: none;
}

.ui-grid-header-cell {
  .md-button {
    margin: 2px;
  }
}

.ui-grid-cell {
  .ui-grid-cell-contents {
    .md-button {
      margin: 2px;
    }
  }
}
