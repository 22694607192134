// Font path
// --------------------------

@font-face {
  font-family: '#{$zmdi-font-name}';
  src: url('#{$zmdi-font-path}/Material-Design-Iconic-Font.woff2?v=#{$zmdi-version}')
      format('woff2'),
    url('#{$zmdi-font-path}/Material-Design-Iconic-Font.woff?v=#{$zmdi-version}')
      format('woff'),
    url('#{$zmdi-font-path}/Material-Design-Iconic-Font.ttf?v=#{$zmdi-version}')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}
