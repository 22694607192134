.garp-article-text {
  display: block;
  margin: 18px 0;
  padding: 2px 0;
  position: relative;

  .language-code,
  .article-text {
    background: none;
    border-color: $black-4;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 1px;
    box-sizing: border-box;
    color: $black-1;
    display: inline-block;
    height: 30px;
    line-height: 26px;
    margin-top: 0;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    -ms-flex-preferred-size: 26px;

    &:focus {
      border-bottom-width: 2px;
      border-color: $accent-color;
      outline: none;
      padding-bottom: 0;
    }

    &::placeholder {
      color: $grey-600;
    }

    &.hidden-text,
    &.hidden-text::placeholder {
      color: transparent;
    }
  }

  .language-code {
    order: 2;
    width: 18%;
  }

  .article-text {
    margin-left: 5px;
    order: 3;
    width: 77%;
  }

  label {
    bottom: 100%;
    color: $black-2;
    order: 1;
    padding-left: 2px;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 6px, 0) scale(0.75);
    transform-origin: left top;
    transition: none;
    white-space: nowrap;
    z-index: 1;
  }
}

.garp-title {
  margin-bottom: 10px;
}

.change-notice {
  background-color: $accent-color;
  border-radius: 3px;
  box-shadow: $whiteframe-shadow-z2;
  box-sizing: border-box;
  color: $base-font-color-light;
  font-size: 15px;
  margin-top: 10px;
  padding: 15px;
}

.garp md-autocomplete[md-floating-label] md-input-container {
  padding-bottom: 0;
}
