// Mixins
//==============

// Create blank string variable to store the !important value.
$important: '';

// Important mixin.
@mixin important {
  // Change the variable value include space and !important value
  // to be used by mixin calls.
  $important: ' !important' !global;

  // Use the content. All mixins call under the important block
  // will applied here.
  @content;

  // Reset the variable to blank string to makes the next mixin
  // calls outside important block will not receiving !important.
  $important: '' !global;
}

//Box solid color variantion creator
@mixin box-solid-variant($color) {
  > .box-header {
    background: $color;
    background-color: $color;
    color: $body-font-color-light;

    a {
      color: #444;
    }
  }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  animation: $animation;
}

@mixin no-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
