.md-calendar-scroll-container {
  width: 100% !important;
  box-shadow: none !important;
}

.md-calendar-scroll-mask {
  width: 100% !important;
}

.md-virtual-repeat-scroller {
  width: 100% !important;
}
