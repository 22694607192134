.customers {
  .customer-type-filter {
    margin-right: 30px;
    user-select: none;

    a {
      color: $display-font-color;
      cursor: pointer;
      font-size: 38px;
      padding: 8px 0;
    }
  }

  .customer-filter {
    min-width: 150px;
  }

  .no-customers {
    margin-left: 10px;
  }
}
