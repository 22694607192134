// Typography
$body-font-size: 16px;
$headline-font-size-base: rem(2.4);
$title-font-size-base: rem(2);
$subhead-font-size-base: rem(1.6);
$caption-font-size-base: rem(1.2);

$base-font-color: #000;
$base-font-color-light: #fff;

$base-line-height: 24px;
$base-link-color: #62b3e7;
$base-heading-color: #181818;
$base-sub-heading-color: #808080;

$base-font-family: 'Roboto', sans-serif;
$base-heading-font-family: 'Roboto', sans-serif;

// Colors
$site-bg-color: #f2f2f2;

$primary-color: $grey-900;
$primary-color-dark: $black;
$primary-color-light: $grey-800;

$accent-color: $blue-500;
$accent-color-dark: $blue-600;
$accent-color-light: $blue-400;

$secondary-color: #c6a37c;

$success-color: $green-500;
$error-color: #c53929;
$link-color: $light-blue-600;

$disabled: #f4f4f4;
$archive-color: #a0a0a0;
$archive-weight: 300;
$dirty: #ffffec;
$saved: $green-100;
$invalid: rgba(245, 105, 84, 0.28);
$blocked: $red-100;

$table-border-color: #d0d0d0;
$table-striped-color: #f2f2f2;
$table-selected-color: f2f2f2;

$customer-color: $blue-50;
$concept-color: $green-50;
$article-color: $deep-orange-50;
$variant-color: $yellow-50;
$supplier-color: $brown-50;
$sales-color: $red-50;
$purchase-color: $green-50;
$garp-color: $yellow-50;

$display-font-color: lighten($base-font-color, 34%);
$subhead-font-color: lighten($base-font-color, 24%);
$title-font-color: lighten($base-font-color, 24%);
$body-font-color: lighten($base-font-color, 24%);
$caption-font-color: lighten($base-font-color, 46%);

$display-font-color-light: darken($base-font-color-light, 2%);
$subhead-font-color-light: darken($base-font-color-light, 2%);
$title-font-color-light: darken($base-font-color-light, 2%);
$body-font-color-light: darken($base-font-color-light, 2%);
$caption-font-color-light: darken($base-font-color-light, 24%);

$item-background-color: $grey-200;

// Shadows
$shadow-multiplier: 0.7;
$shadow-key-umbra-opacity: $shadow-multiplier * 0.2;
$shadow-key-penumbra-opacity: $shadow-multiplier * 0.14;
$shadow-ambient-shadow-opacity: $shadow-multiplier * 0.12;

$whiteframe-shadow-z1: 0 1px 2px rgba(0, 0, 0, 0.3);
$whiteframe-zindex-z1: 1;
$whiteframe-shadow-z2: 0 3px 6px rgba(0, 0, 0, 0.4);
$whiteframe-zindex-z2: 2;
$whiteframe-shadow-z3: 0 3px 6px rgba(0, 0, 0, 0.4);
$whiteframe-zindex-z3: 3;

// Layout size
$content-width: 1620px;
$content-width-2: 1320px;
$content-width-3: 920px;
$baseline-grid: 8px;
$item-padding: 18px;
$layout-gutter-width: 18px;
