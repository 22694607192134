.entities-list {
  [md-cell] {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name-column {
    max-width: 300px;

    &.tight {
      max-width: 170px;
    }
  }

  .identifier-column,
  .parent-column {
    max-width: 150px;

    &.tight {
      max-width: 100px;
    }
  }
}

.entities .archive-header {
  color: lighten($caption-font-color, 10%);
  font-size: 18px;
}
