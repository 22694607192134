.users {
  .default-user {
    border-radius: 50%;
    margin-top: 7px;
  }

  .admin-filter {
    min-width: 180px;
  }
}
