$roboto-font-path: '/assets/fonts';

@mixin roboto-font($type, $weight, $style: normal) {
  @font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/Roboto-#{$type}.eot');
    src: url('#{$roboto-font-path}/Roboto-#{$type}.eot?#iefix')
        format('embedded-opentype'),
      url('#{$roboto-font-path}/Roboto-#{$type}.woff2') format('woff2'),
      url('#{$roboto-font-path}/Roboto-#{$type}.woff') format('woff'),
      url('#{$roboto-font-path}/Roboto-#{$type}.ttf') format('truetype'),
      url('#{$roboto-font-path}/Roboto-#{$type}.svg#Roboto') format('svg');
    font-weight: $weight;
    font-style: $style;
  }

  @font-face {
    font-family: 'Roboto-#{$type}';
    src: url('#{$roboto-font-path}/Roboto-#{$type}.eot');
    src: url('#{$roboto-font-path}/Roboto-#{$type}.eot?#iefix')
        format('embedded-opentype'),
      url('#{$roboto-font-path}/Roboto-#{$type}.woff2') format('woff2'),
      url('#{$roboto-font-path}/Roboto-#{$type}.woff') format('woff'),
      url('#{$roboto-font-path}/Roboto-#{$type}.ttf') format('truetype'),
      url('#{$roboto-font-path}/Roboto-#{$type}.svg#Roboto') format('svg');
  }
}

@mixin roboto-font-pair($type, $weight) {
  @include roboto-font($type, $weight);
}

@include roboto-font-pair('Light', 300);
@include roboto-font-pair('Regular', 400);
@include roboto-font-pair('Medium', 500);
