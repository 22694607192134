.file-drop-overlay {
  background-color: #fff;
  color: $primary-color-dark;
  filter: alpha(opacity=0);
  font-size: 200px;
  height: 100%;
  left: 0;
  line-height: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.25s, visibility 0.25s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}
.file-drop-overlay-xs {
  font-size: 20px;
}
.file-drop-overlay-small {
  font-size: 50px;
}
.file-drop-overlay.dropping {
  filter: alpha(opacity=50);
  opacity: 0.5;
  visibility: visible;
  &:after {
    content: '\f22a';
    display: inline-block;
    font-family: Material-Design-Iconic-Font;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  &.file-drop-overlay-disabled {
    background-color: lighten($error-color, 25);
    &:after {
      color: $error-color;
      content: '\f330';
    }
  }
}
.drop-zone-area {
  &:hover .drop-zone-area-tools {
    display: block;
  }
  cursor: pointer;
  position: relative;
  background-color: #f7f7f7;
  padding: 30px;
  text-align: center;
  border: 2px dashed #cdcdcd;
  border-radius: 3px;
  color: $grey-500;
}
.drop-zone-area-tools {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}
