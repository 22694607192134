// Bordered icons
// -------------------------

.#{$zmdi-css-prefix}-border {
  padding: 0.1em 0.25em;
  border: solid 0.1em $md-border-color;
  border-radius: 2px;
}

.#{$zmdi-css-prefix}-border-circle {
  padding: 0.1em 0.25em;
  border: solid 0.1em $md-border-color;
  border-radius: 50%;
}
