load-spinner {
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  visibility: hidden;
  display: none;

  &.fullscreen {
    height: 100vh;
    position: fixed;
    width: 100vw;
  }
}

load-spinner.loading {
  animation-name: fadeIn;
  animation-duration: 0.2s;
  opacity: 1;
  visibility: visible;
  display: block;
}

.spinner {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}
