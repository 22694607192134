.modified-hint,
.disabled-hint {
  min-height: 24px;
  order: 4;
  overflow: hidden;
  position: relative;

  > div {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    padding-top: 5px;
  }
}

.modified-hint > div {
  color: $accent-color;
  padding-right: 50px;
}

.disabled-hint > div {
  color: $grey-400;
}

.disabled-hint .md-button {
  height: 17px;
  margin-left: 10px;
  margin-top: 0;
  padding: 1px 5px;
}
