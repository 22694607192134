.article-dialog {
  width: 1000px;

  h3 {
    margin-bottom: 0;

    &.topmost {
      margin-top: 0;
    }
  }
}
