.assigned-user-chip {
  background-color: $item-background-color;
  color: $body-font-color;
  height: 30px;
  margin: 4px 2px;
  overflow: hidden;
  padding: 8px;
  cursor: pointer;

  .content {
    position: relative;
    height: 100%;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.confirmation-Accepted {
    color: $body-font-color-light;
    background-color: $success-color;
  }

  &.confirmation-Declined {
    color: $body-font-color-light;
    background-color: $error-color;
  }
}

.assigned-status {
  text-align: center;
  font-size: 14px;
}
