.file-list {
  .select-checkbox {
    width: 20px;

    > div {
      // Fix weird bug in md-table
      display: none !important;
    }
  }

  table > tbody > tr > td {
    color: $body-font-color;
    font-size: $caption-font-size-base;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  thead {
    user-select: none;
  }

  tr {
    cursor: pointer;
  }

  td {
    &.name {
      max-width: 250px;
      i {
        color: $caption-font-color;
      }
    }

    &.extension {
      max-width: 48px;
    }
  }

  table > thead > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 0 10px !important;
  }

  .context-menu {
    cursor: pointer;
    box-shadow: $whiteframe-shadow-z2;
    transition: none;
    z-index: 10;
  }
}
