.bottom-menu {
  z-index: 500;
  position: fixed;
  right: 0;
  bottom: 0;

  .menu-header {
    position: relative;
    height: 50px;
    padding: 15px;

    .menu-title {
      font-weight: 400;
      font-size: $title-font-size-base;
      color: $title-font-color-light;
    }

    .menu-status {
      font-weight: 400;
      color: $title-font-color-light;
      margin-left: 5px;
    }

    .menu-tools {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      > button {
        color: $title-font-color-light;
      }
    }

    .menu-progress {
      position: absolute;
      bottom: 0;
      padding: 0;
      left: 0;
      width: 100%;
    }
  }

  .menu-content {
    background-color: #fff;
  }
}
