.file-browser {
  .tree-container {
    max-width: 380px;
    min-width: 300px;
    overflow-x: auto;
  }

  .files-container {
    min-height: 650px;
    overflow: hidden;
    position: relative;
  }

  md-sidenav md-input-container {
    width: 100%;
  }
}
