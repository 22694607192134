.grid {
  transform: none;

  .row-header,
  .left {
    background-color: $grey-100 !important;
  }

  .ng-dirty {
    background-color: $dirty !important;
  }

  .ng-invalid {
    background-color: $invalid !important;
  }

  .ui-grid-cell-contents {
    border-bottom: 1px solid $grey-200;

    &.is-saved:not(.ng-dirty) {
      background-color: $saved !important;

      &.is-blocked {
        background: linear-gradient(
          $blocked 0%,
          $blocked 50%,
          $saved 50%,
          $saved 100%
        );
      }
    }
  }

  .is-blocked {
    background-color: $blocked !important;
  }

  .ui-grid-top-panel {
    font-weight: 500;
  }

  .ui-grid-cell-focus {
    background: none;
  }

  .ui-grid-cell {
    position: relative;

    input {
      height: 100%;
      padding: 5px;
      width: 100%;
    }
  }

  .price-cell-button {
    background-color: $grey-100;
    font-size: 14px;
    height: auto;
    line-height: 0.8;
    margin: 2px;
    margin-left: 5px;
    min-height: 0;
    padding: 1px 3px;
    width: auto;
  }
}

.matrix-cell-container {
  height: 100%;
  position: relative;
}

md-tooltip.matrix-tooltip div {
  height: auto;
  padding: 1px;
}

.matrix-input-cell {
  max-width: 265px;
  min-width: 200px;

  md-input-container {
    padding: 0px;
    margin: 0 0;

    .md-input {
      padding-left: 0px;
      font-size: 13px;
    }
  }
}
