// Variables
// --------------------------

@use 'sass:math';

$zmdi-font-name: 'Material-Design-Iconic-Font' !default;
$zmdi-font-path: '/assets/fonts' !default;
$zmdi-icon-prefix: 'icon';
$zmdi-css-prefix: 'icon-hc';
$zmdi-version: '2.1.0' !default;

$zmdi-border-color: #9e9e9e !default;
$zmdi-inverse: #ffffff !default;
$zmdi-li-width: math.div(30em, 14) !default;

// Legacy code for backward capability with 2.0.x version
$md-font-path: '../fonts' !default;
$md-css-prefix: zmd !default;
$md-icon-prefix: zmdi !default;
$md-version: '2.1.0' !default;

$md-border-color: #9e9e9e !default;
$md-inverse: #ffffff !default;
$md-li-width: math.div(30em, 14) !default;
