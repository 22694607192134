.task-item {
  padding: 10px;
  background: #fafafa;
  margin-bottom: 2px;
  color: #444;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: #f5f5f5;
  }

  &:hover,
  &.row-tools-visible {
    .row-tools {
      display: block;
    }
  }

  &.active {
    background: #f0f0f0;
  }
}

.task-item-content {
  position: relative;

  .badge > div {
    max-width: 200px;
  }
  .task-done {
    display: inline-block;
    margin: 0;
  }

  .task-name {
    margin-right: 40px;

    > * {
      margin-left: 5px;
      white-space: nowrap;
    }
  }

  .task-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 330px;
  }

  .task-due-date {
    color: #a0a0a0;

    &.expired {
      color: #d43f3a;
    }
  }

  .row-tools {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }
}

.tasks-container-header {
  margin-bottom: 5px;

  span {
    color: $subhead-font-color;
    cursor: pointer;
    font-size: 18px;
    padding: 8px 0;
  }

  md-checkbox {
    margin: 0;
  }
}

.task {
  margin-left: $layout-gutter-width;

  .new-task-form {
    margin-bottom: 5px;
  }

  .task-header {
    margin-bottom: 10px;

    .task-title {
      display: block;
      font-size: 18px;
      font-weight: 500;
      max-width: 100%;
      overflow: hidden;
      word-wrap: break-word;
      white-space: normal;
    }
  }
}

.tasks {
  .filter-selection .selection-input {
    max-width: 280px;
    width: 280px;
  }
}

.tasks-description {
  background: #e3f2fd;
}
