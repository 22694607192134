.customer-currency {
  background-color: $item-background-color;
  color: $body-font-color;
  height: 30px;
  margin: 4px 2px;
  overflow: hidden;
  padding: 8px;

  .content {
    position: relative;
    height: 100%;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover .row-tools {
    button {
      display: inline-block;
    }
  }
  .flag-hidden {
    visibility: hidden;
  }
  .row-tools {
    width: 100px;
    text-align: right;
    button {
      display: none;
    }
  }

  .not-included {
    text-decoration: line-through;
  }

  .main-flag {
    margin-right: 5px;
  }

  .disable-anim {
    transition: opacity 200ms linear, transform 200ms ease;
    transform: translateX(1rem);
    animation: pulse-color 1s linear;
    animation-delay: 200ms;
  }
  .disabled {
    opacity: 0.5;
    transform: translateX(0);
    animation: none;
  }

  .check {
    padding: 0 0.5rem;
    opacity: 1;
    transition: opacity 200ms ease, transform 200ms ease;
    transform: translateX(0);
  }
  .hidden {
    opacity: 0;
    transform: translateX(-1rem);
  }

  @keyframes pulse-color {
    0% {
      color: inherit;
    }
    25% {
      color: $blue-500;
    }
    50% {
      color: inherit;
    }
    75% {
      color: $blue-500;
    }
    100% {
      color: inherit;
    }
  }
}

.mb-1 {
  margin-bottom: 1rem;
}
