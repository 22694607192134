@use 'sass:math';

$font-size: #{math.div($body-font-size, 16px)};

html,
body {
  background: $site-bg-color;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  list-style-type: none;
}

a {
  color: $link-color;
  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

* {
  outline: 0;
}

.center {
  text-align: center;
  vertical-align: middle;
}

.hidden-input {
  background: transparent;
  border: 0;
  bottom: 0;
  color: transparent;
  height: 1px;
  left: -100px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: fixed;
  resize: none;
  user-select: text;
  width: 1px;
  z-index: -1;
}

.clickable {
  cursor: pointer;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
}

// Tables
table {
  border-collapse: collapse;
  border-spacing: 0;

  &.striped > tbody {
    > tr:nth-child(odd) {
      background-color: $table-striped-color;
    }

    > tr > td {
      border-radius: 0;
    }
  }
}

td,
th {
  padding: 0;
}

table,
th,
td {
  border: 0;
}

thead {
  border-bottom: 1px solid $table-border-color;
}

td,
th {
  border-radius: 2px;
  display: table-cell;
  font-weight: normal;
  padding: 12px 5px;
  text-align: left;
  vertical-align: middle;
}

.disabling-overlay {
  @include no-select();
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
}
