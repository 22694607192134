.variant-image-container {
  height: 250px;
  width: 250px;
  position: relative;

  img {
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .image-tools {
    display: none;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover .image-tools {
    display: block;
  }

  &.placeholder {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('./assets/images/250x250.png');
  }
}

.artwork-document-container {
  margin-top: 10px;
  position: relative;
  white-space: nowrap;

  .artwork-document {
    background-color: #f7f7f7;
    border: 2px dashed #cdcdcd;
    border-radius: 5px;
    color: $caption-font-color;
    height: 55px;
    padding: 15px;
    text-align: left;
    user-select: none;

    &:hover .artwork-document-tools {
      display: block;
    }

    .empty {
      text-align: center;
    }
  }

  .artwork-document-tools {
    display: none;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 5px;
  }

  .artwork-document-title {
    cursor: pointer;
    float: left;
    max-width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.purchase-frame,
.sales-frame {
  min-height: 500px;
  overflow: hidden;
  position: relative;

  .refresh-button {
    margin: 18px 0;
  }
}

.total-price {
  md-select {
    margin-bottom: 4px;
    margin-top: 0;
  }

  .price {
    margin-right: 10px;
  }
}

.display-currency-picker {
  width: 100px;
  margin: 0;
}

.price-tools {
  margin-left: auto;

  > div > span {
    margin-right: 5px;
  }

  md-select {
    margin-bottom: 4px;
    margin-top: 0;
  }

  md-checkbox {
    padding-bottom: 4px;
  }
}

.matrix-grid {
  width: 100%;
}

.greyed-out {
  color: #858585;
  height: 24px;
}
