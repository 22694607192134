.matrix-group-dialog {
  width: 350px;
}

.matrix-group-dialog,
.article-dialog {
  .node-values {
    height: auto;
    margin-top: 16px;
    width: 100%;
  }

  .node-value {
    background-color: $item-background-color;
    color: $base-font-color;
    height: 30px;
    margin: 4px 2px;
    overflow: hidden;
    width: 100%;

    span {
      margin-left: 6px;
      max-width: 265px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .md-button {
      margin-left: auto;
    }
  }
}

.matrix-group-autocomplete,
.article-dialog-autocomplete,
.article-management-autocomplete {
  li {
    .highlight {
      color: $primary-color;
    }

    .matrix-group {
      color: $accent-color;
    }
  }
}
