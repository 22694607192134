md-dialog {
  border-radius: 0;
  transform: none !important; // IE can't handle transform3D without it being blurry
  min-width: 300px;

  md-dialog-content {
    display: block; // Fixes IE 10 padding
  }
}

.md-dialog-container {
  position: fixed;
  top: 0 !important;
  height: 100% !important;
}

.md-dialog-is-showing {
  max-height: none;
}
