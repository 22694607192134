.tab-views,
.tab-nav {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-left: 15px;

  a,
  .template-tab {
    align-items: center;
    border-bottom: 3px solid transparent;
    color: $caption-font-color;
    display: flex;
    font-size: 17px;
    font-weight: 400;
    margin-left: 5px;
    opacity: 0.9;
    padding: 6px 12px;

    &.active {
      border-bottom-color: $accent-color;
      opacity: 1;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.stacked-tab-nav {
  a {
    border: none;
    color: lighten($caption-font-color, 10%);
    font-size: 17px;
    margin-bottom: 8px;
    margin-left: 0;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-top: 8px;

    &.active,
    &:hover {
      border: none;
      border-left: 3px solid $accent-color;
      color: $caption-font-color;
      padding-left: 13px;
    }
  }

  a:last-child {
    margin-bottom: 0;
  }

  .tab-views {
    flex-direction: column;
  }
}

.tab-hide {
  display: none;
}
