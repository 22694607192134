// List Icons
// -------------------------

@use 'sass:math';

.#{$zmdi-css-prefix}-ul {
  padding-left: 0;
  margin-left: $zmdi-li-width;
  list-style-type: none;
  > li {
    position: relative;
  }
}
.#{$zmdi-css-prefix}-li {
  position: absolute;
  left: -$zmdi-li-width;
  width: $zmdi-li-width;
  top: math.div(2em, 14);
  text-align: center;
  &.#{$zmdi-css-prefix}-lg {
    left: (-$zmdi-li-width + math.div(4em, 14));
  }
}
