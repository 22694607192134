md-input-container > label,
md-input-container > .md-placeholder {
  color: $caption-font-color;
}

md-input-container:not(.md-input-invalid).md-input-focused {
  .md-input {
    border-color: $accent-color;
  }

  label {
    color: $accent-color;
  }
}

.md-input,
md-select {
  font-size: 16px;
}

md-autocomplete.selection-input {
  background: none;
  height: auto;

  input {
    background: none;
    line-height: 26px;
    order: 0;

    &::-ms-clear {
      display: none;
    }
  }

  md-autocomplete-wrap {
    box-shadow: none;
    height: auto;
  }
}

.filter-input,
.filter-selection {
  md-input-container {
    padding-bottom: 0;
  }

  md-autocomplete {
    button {
      position: absolute;
      right: 0;
    }
  }
}

md-autocomplete[md-floating-label] {
  padding-bottom: 0;
}

md-input-container {
  padding-left: 0;
  padding-right: 0;

  label {
    white-space: nowrap;
  }

  .md-input {
    width: 100%;
    -ms-flex-preferred-size: auto;
  }
}

md-checkbox {
  border-collapse: separate;
  border-spacing: 0;
  margin-left: 0;
}

form.ng-submitted .md-input.ng-invalid {
  border-color: $red-500;
}

input {
  background-color: white;
  &[type='search'] {
    box-sizing: border-box;
  }
}

// Hack to prevent autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
  -webkit-text-fill-color: #555555 !important;
}
