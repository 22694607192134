branch-selector {
  display: block;
  z-index: 1;
}

.branch-selector-toolbar {
  box-shadow: $whiteframe-shadow-z2;

  > md-menu {
    margin: 0;

    > button {
      font-size: 16px;
    }
  }
}

.branch-ready {
  margin-left: 10px;
}
