// Icon sizes
// -------------------------

@use 'sass:math';

.#{$zmdi-css-prefix}-lg {
  font-size: math.div(4em, 3);
  line-height: (3em * 0.25);
  vertical-align: -15%;
}
.#{$zmdi-css-prefix}-2x {
  font-size: 2em;
}
.#{$zmdi-css-prefix}-3x {
  font-size: 3em;
}
.#{$zmdi-css-prefix}-4x {
  font-size: 4em;
}
.#{$zmdi-css-prefix}-5x {
  font-size: 5em;
}
