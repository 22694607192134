md-menu-item.divider {
  border-top: 1px $caption-font-color-light solid;
  height: 1px;
  margin: 8px 0;
  min-height: 0;
}

.md-menu {
  user-select: none;
}
