folder-tree,
[folder-tree] {
  position: relative;
  user-select: none;
  cursor: pointer;

  .node {
    padding-left: 15px;
  }

  .folder-toggle {
    display: inline-block;
    text-align: center;
    width: 14px;
    i {
      vertical-align: middle;
      font-size: 24px;
      height: 25px;
      color: $caption-font-color;
    }
  }

  .folder-content {
    line-height: 48px;
    white-space: nowrap;

    &.selected {
      .folder-name,
      .folder-name > i {
        font-weight: 500;
        color: $accent-color;
      }
    }

    &.selected::before {
      content: '';
      position: absolute;
      left: 0;
      width: 3px;
      height: 48px;
      border-left: 3px solid $accent-color;
    }
  }

  .folder-name {
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 48px;
    vertical-align: top;
    overflow: hidden;
    max-width: 180px;

    i {
      font-size: 24px;
      height: 27px;
      margin-right: 1px;
      color: $caption-font-color;
      vertical-align: middle;
    }
  }
}
