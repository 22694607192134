html,
body,
textarea {
  color: $body-font-color;
  font: normal normal $font-size;
  font-smooth: always;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.1;
}

small {
  font-size: 70%;
}

.primary-color-text {
  color: $primary-color;
}

.accent-color-text {
  color: $accent-color;
}

.secondary-color-text {
  color: $secondary-color;
}

.caption-text {
  color: $caption-font-color;
}

.caption-text-light {
  color: $caption-font-color-light;
}

.frame-title {
  font-size: $title-font-size-base;
  font-weight: 400;
}

.row-title {
  font-weight: 400;
  margin: 0;
  padding: ($item-padding * 0.5) 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title-text-light {
  color: $title-font-color-light;
}

.text-truncate {
  @include text-truncate;
}
