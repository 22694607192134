md-whiteframe {
  background: #fff;
  margin: 0;
  padding: 40px;
  box-shadow: $whiteframe-shadow-z1;
  position: relative;
}

.md-whiteframe-z0 {
  box-shadow: none;
}

.md-whiteframe-z1 {
  box-shadow: $whiteframe-shadow-z1;
}

.md-whiteframe-z2 {
  box-shadow: $whiteframe-shadow-z2;
}

.md-whiteframe-z3 {
  box-shadow: $whiteframe-shadow-z3;
}
