md-backdrop.md-edit-dialog-backdrop {
  z-index: 81;
}

md-edit-dialog {
  z-index: 82;
}

.compact-row {
  height: 40px !important;
}

md-autocomplete.compact-autocomplete {
  height: 25px;
  md-input-container {
    margin: 0;
  }
}
