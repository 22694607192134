.badge {
  padding: 2px 4px;
  text-align: center;
  font-size: 13px;
  color: $caption-font-color;
  cursor: pointer;
  border-radius: 2px;

  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.badge:not([ng-click]) {
  cursor: auto;
}

.badge-customer {
  background-color: $customer-color;
}

.badge-concept {
  background-color: $concept-color;
}

.badge-article {
  background-color: $article-color;
}

.badge-info {
  background-color: $accent-color;
  color: $body-font-color-light;
}

.badge-variant {
  background-color: $variant-color;
}

.badge-supplier {
  background-color: $supplier-color;
}

.badge-purchase {
  background-color: $purchase-color;
}

.badge-sales {
  background-color: $sales-color;
}

.badge-garp {
  background-color: $garp-color;
}
