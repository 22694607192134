.tier-price {
  padding: 2px 0;

  &.main .price::before {
    border-left: 5px solid $accent-color;
    content: '';
    height: 48px;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
  }

  .price {
    background: $item-background-color;
    height: 48px;
    padding: 6px 18px;
    position: relative;

    input {
      width: 80px;
    }

    &.edit .row-tools button {
      display: inline-block;
    }
  }

  .fixed-sales-price {
    font-weight: 500;
  }

  &:hover .row-tools {
    button {
      display: inline-block;
    }
  }

  .row-tools {
    list-style: none outside none;

    button {
      display: none;
    }
  }
}
