.tier-price-list {
  .tier-menu {
    height: 52px;
  }

  .headers {
    font-weight: 500;
    padding: 6px 18px;
    white-space: nowrap;
    height: 45px;
    background: $item-background-color;
    margin-bottom: 2px;
    cursor: pointer;
    user-select: none;
  }

  .list {
    position: relative;
    min-height: 250px;
  }

  .disabling-warning {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
  }

  .grid-button {
    width: 8%;
  }

  .tools {
    width: 12%;
  }

  &.purchase {
    .quantity {
      width: 20%;
      font-weight: 500;
    }
    .base-price {
      width: 20%;
    }
    .decimals-in-price {
      width: 15%;
    }
    .total-purchase-price {
      width: 25%;
    }
  }

  &.sales {
    .quantity {
      width: 9%;
      font-weight: 500;
    }
    .decimals-in-price {
      width: 9%;
    }
    .total-purchase-price {
      width: 17%;
      .diff {
        color: $grey-500;
      }
    }
    .fixed-sales-price {
      width: 18%;
    }
    .margin {
      width: 9%;
    }
    .multiplier {
      width: 9%;
    }
    .total-sales-price {
      width: 14%;
    }
    .ready-sales-price {
      width: 10%;
    }
  }
}
