// CONTAINER

.container {
  margin: 20px;
}

// HEAD NAVIGATION
.head-nav {
  background-color: $primary-color-dark;
  height: 64px;

  .org-logo {
    color: $display-font-color-light;
    display: inline-block;
    font-size: 30px;
    opacity: 1;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
  }

  .nav-wrapper {
    height: 100%;
  }

  .navbar {
    margin-left: 50px;
  }

  a {
    color: $body-font-color-light;

    span {
      opacity: 0.85;
    }
  }

  .badge {
    margin-left: 5px;
    margin-top: 2px;
    font-weight: 500;
    opacity: 1;
  }
}

// MAIN CONTENT
.main-content {
  padding: 1rem;
  max-width: 2200px;
  margin-left: auto;
  margin-right: auto;
}

.article-sticky {
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: 1px #83838336 solid;
  background: var(--bg-site, $site-bg-color);
}

.content-header {
  margin: 20px 0;

  .content-title {
    color: $display-font-color;
    font-size: 22px;
    line-height: 1.4em;
    padding: 0;
    white-space: nowrap;
  }

  .sticky-content {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 0;
    margin: 0;
    padding: 5px 0;
    z-index: 3;
    background-color: #f2f2f2;
    border-bottom: 1px #e1e1e1 solid;
  }

  .content-subtitle {
    color: $caption-font-color;
    font-size: 24px;
  }

  .content-tabs {
    margin-left: auto;
  }
}

md-frame {
  margin: 0;
  padding: 0;
}

section {
  min-height: 50px;
}

md-whiteframe {
  padding: ($layout-gutter-width * 2) !important;

  section {
    margin-top: 0;
    margin-bottom: $layout-gutter-width * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.layout-gutter-right {
  padding-right: $layout-gutter-width;
}

.layout-gutter-left {
  padding-left: $layout-gutter-width;
}

.no-padding {
  padding: 0;
}

.filters {
  margin-left: auto;
}

.margin-counter {
  margin-left: -$baseline-grid;
  margin-right: -$baseline-grid;
}

.items-counter-padding {
  margin: -($item-padding * 0.5);
}

.item-padding,
.inner-items-padding > div {
  padding: 0 ($item-padding * 0.5);
}

.column-item-margin {
  margin: ($layout-gutter-width * 0.5) 0;
}

.row-tools {
  white-space: nowrap;
  margin-left: auto;
}
