.filter-input,
.filter-selection {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  position: relative;

  i {
    align-self: center;
    font-size: 25px;
    justify-content: center;
    margin-right: 5px;
  }

  > .icon + input {
    margin-left: 25px;
  }

  .selection-input {
    max-width: 160px;
  }
}

.input-label {
  color: $caption-font-color;
  font-size: 12px;
  display: block;
  height: 24px;
}

#shared-category-code,
.form-control {
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $disabled !important;
    cursor: not-allowed;
  }

  &.ng-dirty.ng-valid {
    background-color: $dirty;
  }
}

.input-suffix {
  position: absolute;
  right: 4px;
  top: 8px;
  color: #cacaca;
}
