$spinner-font-color: #fafafa;

md-progress-circular .md-inner .md-left .md-half-circle {
  border-left-color: $spinner-font-color;
  border-top-color: $spinner-font-color;
}
md-progress-circular .md-inner .md-right .md-half-circle {
  border-right-color: $spinner-font-color;
  border-top-color: $spinner-font-color;
}
