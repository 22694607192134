.uploads-menu {
  background-color: $primary-color-light;
  width: 500px;
  box-shadow: $whiteframe-shadow-z2;

  .file-uploads {
    display: block;
    max-height: 350px;
    width: 100%;
    overflow: auto;
  }

  .file {
    position: relative;
    height: 54px;
    padding: 15px;
    width: 100%;
    white-space: nowrap;
    border-bottom: 1px solid $item-background-color;

    &:last-child {
      border-bottom: none;
    }

    > div {
      padding: 0 6px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-size {
      width: 85px;
    }

    .file-status {
      width: 65px;
    }

    .file-tools {
      margin-left: auto;
    }

    .file-progress {
      width: 100px;
    }
  }
}
